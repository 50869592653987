<template>
  <div>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Members</h4>
          </template>
          <template v-slot:body>
            <div v-if="loadTable" class="text-center">
              <b-spinner small type="grow"></b-spinner>
              Loading...
            </div>
            <b-table v-else striped responsive :fields="columns"
                     :items="allPackages" class="mb-0 table-borderless "  id="table-transition-example" :tbody-transition-props="transProps">
              <template v-slot:cell(name)="data1">
                <router-link :to="{name:'profile', params:{id :data1.item.id}}">
                  <h5><b-badge variant="primary">{{data1.item.name}}</b-badge></h5>
                </router-link>
              </template>
              <template v-slot:cell(package_name)="data1">
              <h5><b-badge variant="primary">{{data1.item.name}}</b-badge></h5>
              </template>
            </b-table>
            <b-pagination v-model="currentPage"
                          class="mt-3"
                          :total-rows="total"
                          align="right"
                          :per-page="per_page"
                          aria-controls="my-table"
                          @input="fillTable"
            ></b-pagination>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import { mapGetters } from 'vuex'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import reportService from '../../../reports/services/reports'
export default {
  name: 'allPackages',
  data () {
    return {
      packageInfo: '',
      showPhone: '',
      transProps: {
        // Transition name
        name: 'flip-list'
      },
      filter: {
        project: '',
        title: '',
        status: '',
        type: ''
      },
      dateRange: {
        startDate: '',
        endDate: ''
      },
      currentPage: 1,
      per_page: 10,
      total: 6,
      loadTable: true,
      columns: [
        { label: 'Name', key: 'name', class: 'text-left' },
        { label: 'Phone', key: 'phone', class: 'text-left' },
        { label: 'Package Name', key: 'package_name', class: 'text-left' }
      ],
      allPackages: []
    }
  },
  computed: {
    ...mapGetters(['getUserProjects'])
  },
  methods: {
    resetFilter () {
      this.filter = {
        project: '',
        title: '',
        status: '',
        type: ''
      }
      this.filterTable()
    },
    fillTable () {
      this.loadTable = true
      reportService.getPurchases(this.$route.params.id, this.$route.query).then(res => {
        this.loadTable = false
        this.allPackages = res.data.data.data
        this.currentPage = res.data.data.current_page
        this.per_page = res.data.data.per_page
        this.total = res.data.data.total
      })
    },
    showInfo (id) {
      this.$store.dispatch('getSpacificPackages', id).then(data => {
        // console.log(data.data.data)
        this.packageInfo = data.data.data
        this.$bvModal.show('packageDetails')
      })
    }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    DateRangePicker
  },
  mounted () {
    core.index()
  },
  created () {
    this.fillTable()
  }
}
</script>
<style>
.nav-link.router-link-exact-active.router-link-active{
  background: var(--iq-primary) !important;
  color: #fff !important;
}
.colorSpan{
  width: 20px;
  height: 20px;
}
[dir=ltr][mode=light] .nav-tabs {
  background: #fff !important;
  border-radius: 10px !important;

  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
th{
  white-space: pre !important;
  vertical-align: middle !important;
}
td{
  white-space: pre !important;
  vertical-align: middle !important;
}
</style>
